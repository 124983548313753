import React, { useEffect, useState } from 'react';
import './css/nextpage.css';
import ProgressBar from './ProgressBar';
import { useNavigate } from 'react-router-dom';

const NextPage = () => {
  const navigate = useNavigate();
  const [currentStep] = useState(2);
  const [pairs, setPairs] = useState([]);

  useEffect(() => {
    fetchPairs();
  }, []);

  const fetchPairs = async () => {
    try {
      const response = await fetch("https://api.secretssanta.org/shuffle-participants", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log(data); // Ausgabe der gesamten Antwort zur Überprüfung
      setPairs(data.pairs || []); // Fallback auf leeres Array, falls `pairs` nicht vorhanden
    } catch (error) {
      console.log("Error:", error);
      setPairs([]); // Setze `pairs` auf leeres Array bei Fehler
    }
  };


  return(
    <div className="next-page-container">
      <div className='content'>

        <header className="App-header">
          <h1>Secret</h1>
          <h1 className='Santa'>Santa</h1>
        </header>

        <div className="progressBar">
          <div className="bar">
            <ProgressBar currentStep={currentStep} />
          </div>
        </div>

        <div className="pairs">
        <h2>Step 2</h2>
        <p>Check if the pairing is ok</p>
        <div>
          <strong>Number of participants:</strong> {pairs.length * 2 / 2}
        </div>
          <div className="pairs-container">
            {pairs.map((pair, index) => (
              <div key={index} className="pair-row">
                <div className="pair-item">{pair.giver}</div>
                <div className="pair-item">{pair.receiver}</div>
              </div>
            ))}
          </div>
          <div className="button-row">
            <button onClick={() => navigate('/send')} className="next-button">Next</button>
            <button onClick={fetchPairs} className="regenerate-button">Regenerate</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextPage;
