import './css/App.css';
import LandingPage from './LandingPage';
import NextPage from './NextPage';
import SendPage from './SendPage';
import SuccessPage from './SuccessPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


const App = () => {
  return(
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/next" element={<NextPage />} />
        <Route path="/send" element={<SendPage />} />
        <Route path='/success' element={<SuccessPage/>} />
      </Routes>
    </Router>
  )
}

export default App;
