import React, { useState } from 'react';
import './css/ParticipantForm.css';
import { useNavigate } from 'react-router-dom';

const ParticipantForm = () => {

  const navigate = useNavigate();

  const [participants, setParticipants] = useState([
    { name: '', email: '' },
    { name: '', email: '' },
    { name: '', email: '' }
  ]);

  const handleInputChange = (index, event) => {
    const values = [...participants];
    values[index][event.target.name] = event.target.value;
    setParticipants(values);
  };

  const handleAddParticipant = () => {
    setParticipants([...participants, { name: '', email: '' }]);
  };

  const updateParticipants = async () => {
    try {
      const response = await fetch("https://api.secretssanta.org/update-participants", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(participants),
      });

      const result = await response.json();
      console.log(result.message);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const shuffleParticipants = async () => {
    try {
      const response = await fetch("https://api.secretssanta.org/shuffle-participants", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      console.log(result.pairs);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleNext = async () => {
    await updateParticipants();
    navigate('/next');
  };

  const handleSendDirect = async () => {
    await updateParticipants();
    await shuffleParticipants();
    navigate('/send');
  };

  return (
    <div className="form-container">
      <h2>Step 1</h2>
      <p>Input all the participants</p>
      <div className="header-row">
        <strong>Name</strong>
        <strong>Email</strong>
      </div>
      {participants.map((participant, index) => (
        <div key={index} className="input-row">
          <input
            type="text"
            name="name"
            placeholder="John Doe"
            value={participant.name}
            onChange={(event) => handleInputChange(index, event)}
            className="input-field"
          />
          <input
            type="email"
            name="email"
            placeholder="john.doe@example.com"
            value={participant.email}
            onChange={(event) => handleInputChange(index, event)}
            className="input-field"
          />
        </div>
      ))}
      <button onClick={handleAddParticipant} className="add-button">+</button>
      <div>
        <strong>Number of participants:</strong> {participants.length}
      </div>
      <div className="button-row">
        <button onClick={handleNext} className="next-button">Next</button>
        <button onClick={handleSendDirect} className="send-button">Straight to Send</button>
      </div>
    </div>
  );
};

export default ParticipantForm;
