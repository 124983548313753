import React from 'react';
import "./css/SuccessPage.css"
import { useNavigate } from 'react-router-dom';
// import Footer from "./footer.js"

function SuccessPage() {

    const navigate = useNavigate();

    return(
        <div className="final-page-container">
            <div className='successpage'>
                <header className="App-header">
                    <h1>Secret</h1>
                    <h1 className='Santa'>Santa</h1>
                </header>

                <h2>Congrats!</h2>
                <button onClick={() => navigate('/')} className="start-over-button">Start Over</button>
                <div className="links">
                    <div className='linkedinbutton'>
                        <p>Created by Julian<a href="https://www.linkedin.com/in/julian-gebhard-201955265/" className="fa fa-linkedin-square" target="_blank" rel="noopener noreferrer" aria-label="Julian's LinkedIn"></a></p>
                        <p>Special thanks to Chris<a href="https://www.linkedin.com/in/ckafrouni/" className="fa fa-linkedin-square" target="_blank" rel="noopener noreferrer" aria-label="Chris' LinkedIn"></a>for the design.</p>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
      </div>
    );
};

export default SuccessPage;