import React from 'react';


function Footer() {

    return(
    <footer className="App-footer">
        <p>We do not store nor use any of the information entered in this form for a different use case.</p>
    </footer>
    );
};

export default Footer;