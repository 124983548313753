import React, { useState, //useEffect 
    } from 'react';
import ProgressBar from './ProgressBar';
import ParticipantForm from './InputForm';
import Footer from './footer';

function LandingPage() {



  // current step on progress bar
  const [currentStep] = useState(1);


  return (
    <div className="App">
      <header className="App-header">
        <h1>Secret</h1>
        <h1 className='Santa'>Santa</h1>
      </header>

      <div className="content">
        <div className="progressBar">
          <div className="bar">
            <ProgressBar currentStep={currentStep} />
          </div>
        </div>

        {/* <p> The current time is {currentTime}.</p> */}

        <ParticipantForm />

      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
