import React from 'react';
import './css/ProgressBar.css';

const ProgressBar = ({ currentStep }) => {
  return (
    <div className="progress-container">
      <div className={`circle ${currentStep >= 1 ? 'active' : ''}`}></div>
      <div className={`line ${currentStep >= 2 ? 'active' : ''}`}></div>
      <div className={`circle ${currentStep >= 2 ? 'active' : ''}`}></div>
      <div className={`line ${currentStep >= 3 ? 'active' : ''}`}></div>
      <div className={`circle ${currentStep >= 3 ? 'active' : ''}`}></div>
    </div>
  );
};

export default ProgressBar;