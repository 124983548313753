import React, { useState } from 'react';
import './css/SendPage.css';
import ProgressBar from './ProgressBar';
import { useNavigate } from 'react-router-dom';

const SendPage = ({ participants = [] }) => {
  const [subject, setSubject] = useState("Secret Santa's Here");
  const [signature, setSignature] = useState("Santa's assistant");
  const navigate = useNavigate();
  const [currentStep] = useState(3);

  const handleSend = async () => {
    try {
      const response = await fetch("https://api.secretssanta.org/send-emails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const result = await response.json();
      console.log(result.message);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleSendButton = async () => {
    await handleSend();
    navigate('/success');
  };



  return (
    <div className="send-page-container">
      <div className='sendpage'>
        <header className="App-header">
          <h1>Secret</h1>
          <h1 className='Santa'>Santa</h1>
        </header>

        <div className="progressBar">
          <div className="bar">
            <ProgressBar currentStep={currentStep} />
          </div>
        </div>
        <h2>Step 3</h2>
        <p>Customize and send emails</p>
        <div>
          <strong>Number of participants:</strong> {participants.length}
        </div>
        <div className="input-group">
          <label>Subject</label>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="input-field-sendpage"
          />
        </div>
        <div className="input-group">
          <label>Signature</label>
          <input
            type="text"
            value={signature}
            onChange={(e) => setSignature(e.target.value)}
            className="input-field-sendpage"
          />
        </div>
        <div className="button-row">
          <button onClick={handleSendButton} className="send-button-sendpage">Send</button>
          <button onClick={() => navigate('/')} className="reset-button">Reset Template</button>
        </div>
      </div>
    </div>
  );
};

export default SendPage;
